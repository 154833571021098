<template>
  <section class="modal">
    <div class="modal-content">
      <h1>Dédoublonnage manuel</h1>
      <p>Vous vous apprêtez à supprimer {{ count }} candidatures, cette action est définitive.</p>
      <div class="modal-buttons">
        <button type="button" class="button-default" @click="close">Annuler</button>
        <button type="button" class="button-default button-confirm" @click="confirm">Confirmer</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ModalConfirmDeduplication',
  props: {
    count: Number
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm')
    }
  }
}
</script>
